:root {
    --white: #ffffff;
    --white20: rgba(255, 255, 255, .2);
    --black: rgba(6,8,4,1);
    --grey: #999;

    --primary: var(--black);
    --secondary: var(--white);

    --assertive: #ff00ff;
    --focus: #ff00ff;
    --color-error: #ff0000;
    --color-sucess: #4dc247;
}