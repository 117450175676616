#Interface__Canvas,
#scene-three,
#scene-target {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include z-index($z-index-bg);
  pointer-events: none;
}

#scene-target {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        height: 40vw;
        width: 40vw;
    }
    
    @media (max-width: $smartphone) {
        span {
            height: 90vw;
            width: 90vw;
        }
    }
}
