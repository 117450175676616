.block-player-video {
    --fit: contain;
    
    --interface-height: #{rem(18)};
    --interface-gap: var(--padding-xxxs);

    --bg-track: transparent;
    --bg-thumb: var(--black);

    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-l); 

    @media (max-width: $smartphone) {
        --interface-gap: var(--padding-xxxs);
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
    }
}

.block-player-video {
    width: 100%;
    max-height: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding: calc(var(--header-height) + var(--padding-v)) var(--padding-h) calc(var(--footer-height) + var(--padding-v));
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (min-width: $smartphone) {
        > div {
            width: 100%;
            height: 100%;
            position: relative;
        }
    }
}

.player-video {
    position: relative;
    height: 100%;
    
    > video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: var(--fit);
        object-position: center center;
    }
    
    @media (max-width: $smartphone) {
        display: flex;
        flex-direction: column;

        > video {
            order: 1;
        }

        .player-video__overlay {
            order: 0;
        }
        
        .player-video__overlay:last-child {
            order: 2;
        }
    }

    @media (min-width: $smartphone) {
        background-color: var(--black);

        @include isCursor() {
            &__overlay:last-child {
                opacity: 0;
                pointer-events: none;
            }

            &:hover {
                .player-video__overlay {
                    opacity: 1;
                    pointer-events: initial;
                }
            }
        }
    }
}

.player-video__overlay {
    width: 100%;
    background-color: var(--primary-color);
    color: var(--black);
    padding: calc(var(--interface-gap) / 2) var(--interface-gap);

    @media (min-width: $smartphone) {
        position: absolute;
        left: 0;
        top: 0;
        
        &:last-child {
            bottom: 0;
            top: auto;
        }
    }
}

.player-video__interface {
    width: 100%;
    height: var(--interface-height);
    display: flex;
    align-items: center;
    // margin-top: var(--padding-xxxxs);
    gap: var(--interface-gap);
    @include font-base();
    --line-height-sans: .75;

    > button {
        flex: 0;
        background-color: transparent;
        padding: 0;
    }

    .--play & [data-play],
    .--pause & [data-pause],
    .--muted & [data-muted]{
        font-weight: bold;
        pointer-events: none;
    }

    .player-video-full__progress {
        flex: 1 0 350px;
        position: relative;
        border: 1px solid var(--bg-thumb);
        height: var(--interface-height);
        
        cursor: grab;    
        &:active {
            cursor: grabbing;
        }

        > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }

        .track {
            background-color: var(--bg-track);
        }

        .thumb {
            transform-origin: 0 0;
            background-color: var(--bg-thumb);
        }
    }

    .player-video__timer {
        flex: 0;
        background-color: transparent;
    }

    @media (max-width: $smartphone) {
        justify-content: space-between;

        .player-video-full__progress {
            display: none;    
        }
    }
}
