.team-contact {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: var(--gap);

    .panel {
        padding: var(--gap);
        display: flex;
        justify-content: space-between;
        @include font-base();

        a {
            @include basic-a();
            color: var(--primary-color);
            display: flex;
            justify-content: space-between;
            gap: var(--gap);
        }
    }

    @media (max-width: $smartphone) {
        margin-top: var(--gap);
    }

    @media (min-width: $smartphone) {
        grid-template-columns: repeat(2, 1fr);
    }
} 