.block-projects-list {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-l);
    --gap: var(--padding-s);    

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-xxxs);
    }
}

.block-projects-list {
    padding: var(--padding-v) var(--padding-h);

    &.--full {
        padding: calc(var(--header-height) + var(--padding-v)) var(--padding-h) calc(var(--footer-height) + var(--padding-v));
    }

    ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--gap);
    }

    .project {
        @include basic-a();
        @include aspect-ratio(265, 340);
        position: relative;
        border-radius: var(--radius);
        overflow: hidden;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: grayscale(1);
        }

        .overlay {
            @include font-base();
            color: var(--black);
            padding: var(--padding-xxs);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            display: none;

            p {
                @include z-index(1);
                position: relative;
            }

            &::before {
                @include z-index(0);
                content: '';
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                opacity: .9;
                background-color: var(--primary-color);
            }
        }

        @include isCursor() {
            &:hover .overlay {
                display: flex;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        margin-top: var(--padding-m);
    }

    @media (max-width: $smartphone) {
        .project {
            .overlay {
                display: none;
            }
        }
    }

    @media (min-width: $smartphone) {
        ul {
            grid-template-columns: repeat(5, 1fr);
        }

        &__footer {
            margin-top: var(--padding-xl);
        }
    }
}
