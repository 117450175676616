.block-materials-list {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-l);
    --gap: var(--padding-s);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-xxxs);
    }
}

.block-materials-list {
    padding: calc(var(--header-height) + var(--padding-v)) var(--padding-h) calc(var(--footer-height) + var(--padding-v));

    ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: var(--gap);
    }

    img {
        mix-blend-mode: hard-light;
    }

    .material {
        > div {
            padding: var(--padding-s);
        }

        .text {
            @include font-xs();
        }

        .name {
            @include font-xl();
            margin-bottom: var(--padding-xxxs);
        }
    }

    @media (max-width: $smartphone) {
        .material {
            .img {
                width: 100%;
                margin-bottom: var(--gap);
            }
        }
    }

    @media (min-width: $smartphone) {
        ul {
            grid-template-columns: repeat(2, 1fr);
        }

        .material {
            > div {
                display: flex;
                gap: var(--gap);
                height: 100%;
            }
            
            .img {
                align-items: center;
                justify-content: center;
                display: flex;
                flex: 0 0 40%;
            }
        }
    }
}
