.widget-info {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-l);
    --gap: var(--padding-xxs);    

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-xxxs);
    }
}

.widget-info {
    padding: var(--padding-v) var(--padding-h);
    @include font-base();

    .assoc {
        margin-top: var(--gap);
        
        img {
            width: rem(84);
            height: rem(23);
        } 
    }

    .wrapper {
        display: grid;

        > .panel {
            padding: var(--gap);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            .assoc {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                a {
                    @include basic-a();
                    margin-top: em(-12);
                    margin-left: em(10);
                }
            }
        }
    }

    @media (max-width: $smartphone) {
        .wrapper {
            display: grid;

            > .panel:first-child {
                margin-bottom: var(--gap);
            }
        }
    }

    @media (min-width: $smartphone) {
        .wrapper {
            grid-template-columns: repeat(5, 1fr);
            gap: var(--gap);
            
            > .panel {
                grid-column: span 2;
            }

            .team {
                grid-column: span 3;
            }
            
            .team-contact {
                grid-column: span 5;
                grid-template-columns: repeat(5, 1fr);

                > .panel:first-child {
                    grid-column: span 2;
                }

                > .panel:last-child {
                    grid-column: span 3;
                }
            }
        }
    }
}
