@mixin button {
    --color: var(--primary-color);
    --bg: var(--primary-color);
    // --padding-v: var(--padding-xxxxs);
    --padding-v: var(--padding-xxxxs);
    --padding-h: var(--padding-xxxxs);

    @include font-base();
    @include basic-a();

    appearance: none;
    border: 1px solid var(--color);
    cursor: pointer;
    color: var(--color);
    position: relative;
    text-align: center;
    vertical-align: top;
    background: transparent;
    // mix-blend-mode: difference;
    padding: 0 var(--padding-h);
    display: block;
    overflow: hidden;
    
    * { pointer-events: none; }
    span { display: block; }
    
    border-left: var(--padding-h) solid;
    
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(var(--blur));
        @include z-index(0);
    }

    &::before {
        @include z-index(1);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--bg);
        transform: scale3d(0, 1, 1);
        transform-origin: center left;
        transition: transform .3s var(--ease-sd-sound);
    }

    > span {
        position: relative;
        display: block;
        // mix-blend-mode: difference;
        overflow: hidden;
        @include z-index(2);
        // transition: transform .2s ease-out;
        
        > span {
            // padding: var(--padding-v) 0;
            transition: transform .3s var(--ease-sd-sound);
    
            &:last-child {
                position: absolute;
                top: 100%;
                color: var(--black);
            }
        }
    }

    @include isCursor() {
        &:hover {
            > span {
                // transform: translateX(calc(-1 * var(--padding-h) / 2));
                
                > span {
                    transition: transform .3s var(--ease-sd-sound) .2s;
                    transform: translate3D(0, -100%, 0);
                }
            }

            &::before {
                transform: scale3d(1, 1.02, 1);
                transition-delay: .1s;
            }
        }
    }
}

.button {
    @include button;
}