#Preloader {
    --color: var(--primary-color);
    --bg: var(--primary-bg);
    --bar: #{rem(270)};

    @media (max-width: $smartphone) {
        --bar: #{rem(200)};
    }
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    @include z-index($z-index-loader);
    color: var(--color);
    background-color: var(--bg);
    @include font-base();
    
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;

    .progress {
        display: flex;
        flex-direction: column;
        // margin-left: var(--padding-m);

        .bar {
            margin-top: var(--padding-xxxxs);
            margin-bottom: var(--padding-xxxs);

            .track {
                border: 1px solid var(--primary-color);
                height: rem(8);
                width: var(--bar);
                position: relative;
                display: block;
            }

            .thumb {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: var(--primary-color);
                transform: scale3d(0, 1, 1);
                transform-origin: center left;
            }
        }

        .name {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
