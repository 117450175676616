@use "sass:math";

#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--primary-color);
    --bg: transparent;
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    --logo-width: #{rem(197)};
    --logo-height: #{rem(66)};

    @media (max-width: $smartphone) { 
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-xs);
        --logo-width: #{rem(197)};
        --logo-height: #{rem(66)};
    }
}

#Header {
    @include font-base();
    @include z-index($z-index-header);

    position: fixed;
    width: var(--width);
    height: var(--height);
    right: 0;
    top: 0;
    padding: 0 var(--padding-h);
    opacity: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--color);
    background-color: var(--bg);

    transform: translate3d(0, var(--y-header), 10px);

    .toggle-menu {
        @include font-base();
        font-size: rem(18);
        color: inherit;
        background-color: transparent;
        padding: 0;
        position: relative;
    }

    > div:first-child {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .logo {
        span {
            display: block;
            width: var(--logo-width); 
            height: var(--logo-height);
        }

        .wave-line,
        .wave-container {
            opacity: 0;
        }
    }

    nav {
        display: flex;
        align-items: flex-end;
        gap: var(--padding-m);
        
        a {
            display: flex;
            gap: var(--padding-xxxs);

            .icon:nth-child(2) {
                position: absolute;
            }

            .icon {
                height: rem(64);
                width: rem(64);

                svg {
                    transition: transform .05s ease-out;
                }
            }

            &[aria-current="page"] {
                text-decoration: underline;

                .icon:nth-child(2) svg {
                    transform: rotate(290deg);
                }
            }

            @include isCursor() {
                &:hover {
                    .icon:nth-child(2) svg {
                        transform: rotate(75deg);
                    }
                }
            }
        }
    }

    a {
        @include basic-a();
        color: inherit;
    }

    .progress {
        @include font-base();
        --line-height-sans: .8;
        display: flex;
        flex-direction: column;
        margin-left: var(--padding-m);

        .bar {
            margin-top: var(--padding-xxxxs);
            margin-bottom: var(--padding-xxxs);

            .track {
                border: 1px solid var(--primary-color);
                height: rem(18);
                width: rem(190);
                position: relative;
                display: block;
            }

            .thumb {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: var(--primary-color);
                transform: scale3d(0, 1, 1);
                transform-origin: center left;
            }
        }

        .time {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }

    @media (max-width: $smartphone) { 
        align-items: flex-start;
        padding: var(--padding-v) var(--padding-h);

        nav {
            display: none;
        }
    }

    @media (min-width: $smartphone) {
        .toggle-menu {
            display: none;
        }
    }
}
