.team {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--gap);

    .person {
        padding: var(--gap);
        @include font-base();

        figure,
        img {
            border-radius: var(--radius);
        }

        figure {
            margin-bottom: var(--gap);
        }

        a {
            display: block;
            @include basic-a();
            color: var(--primary-color);
        }
    }

    // @media (min-width: $smartphone) {
    //     grid-template-columns: repeat(2, 1fr);
    // }
}