.widget-team {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-l);
    --gap: var(--padding-xxs);    

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-xxxs);
    }
}

.widget-team {
    padding: calc(var(--header-height) + var(--padding-v)) var(--padding-h) var(--padding-v);

    h1 {
        @include font-xxl();
        margin-bottom: var(--padding-xl);
    }

    .team-contact {
        margin-top: var(--gap);
    }

    // @media (max-width: $smartphone) {
    // }

    // @media (min-width: $smartphone) {

    // }
}
