.widget-instagram {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-l);
    --gap: var(--padding-xxs);    

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-xxxs);
    }
}

.widget-instagram {
    padding: var(--padding-v) var(--padding-h);

    .panel {
        padding: var(--gap);

        .wrapper {
            gap: var(--gap);

            > * {
                border-radius: var(--radius);
                @include aspect-ratio(1, 1);
                filter: grayscale(1);

                > div {
                    border-radius: var(--radius);
                    overflow: hidden;

                    position: absolute;
                    height: 100%;
                    width: 100%;

                    top: 0;
                    left: 0;
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            @include isCursor( ) {
                > *:hover {
                    filter: grayscale(0);
                }
            }

        }
    }

    @media (max-width: $smartphone) {
        .panel {
            .wrapper {
                -webkit-overflow-scrolling: touch;
                overflow: hidden;
                overflow-x: scroll;
                scroll-snap-type: x mandatory;

                display: flex;
                flex-wrap: nowrap;
                position: relative;

                &::-webkit-scrollbar {
                    display: none;
                }
        
                > * {
                    scroll-snap-align: start;
                    min-width: 70vw;
                }
            }
        }
    }

    @media (min-width: $smartphone) {
        .panel {
            .wrapper {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                
                > * {
                    grid-column: span 1;
                }
            }
        }
    }
}
