html {
    width: 100%;
    height: 100%;
}

body {
    width: 100vw;
    min-height: 100%;
    color: var(--primary-color);
    background: var(--primary-bg);

    > video {
        visibility: hidden;
        pointer-events: none;
    }
    
    main {
        width: 100%;

        @include font-base();
    }
    
    &:not(.__scroll-manual) {
        overflow: hidden;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);

        .wrap {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100vw;
        }

        // main {
        //     @include z-index($z-index-wrap);
        //     position: fixed;
        //     height: 100%;
        //     top: 0;
        //     left: 0;
        // }
    }
    
    &.__scroll-manual:not(.__noScroll) {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        
        &.__noScroll {
            overflow: hidden;
        }
    }
}

.icon {
    display: inline-block;

    svg {
        height: 100%;
        width: 100%;
    }
}

.line-parent {
    overflow: hidden;
}

*[aria-current="page"] {
    pointer-events: none;
    text-decoration: none;
}

button[disabled] {
    pointer-events: none;
    opacity: .3;
}

// Tweakpane
.tp-dfwv {
    @include z-index($z-index-tweakpane);
}

.label {
    @include font-xs-uppercase();
    margin-bottom: var(--padding-xxs);
    
    a {
        color: var(--primary-color);
        @include basic-a();
    }
}