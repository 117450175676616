#Sidemenu {
    --bg: var(--primary-color);
    --color: var(--black);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-xs);
}

#Sidemenu {
    @include z-index($z-index-sidemenu);
    right: 0;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    
    > .overlay {
        @include z-index(0);
        padding: 0;
        background-color: transparent;
        right: 0;
        position: fixed;
        top: 0;
        height: 100%;
        width: 100%;
    }
    
    > .wrapper {  
        right: 0;
        position: fixed;
        top: 0;

        background-color: var(--bg);
        color: var(--color);
        width: 75vw;
        min-height: rem(275);
        padding: var(--padding-v) var(--padding-h);
    
        @include z-index(1);
        @include font-base();
        font-size: rem(18);
    
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    .toggle-menu {
        padding: 0;
        background-color: transparent;
        top: var(--padding-v);
        position: fixed;
        right: var(--padding-v);
        text-decoration: underline;
    }

    .content {
        > div {
            display: flex;
            flex-direction: column;
            gap: var(--padding-xxxs);
        }
        
        margin-bottom: var(--padding-m);
    }

    .contact {
        margin-top: var(--padding-m);
        display: flex;
        flex-direction: column;
        gap: var(--padding-xxxs);
    }

    .volume {
        margin-top: var(--padding-m);

        button {
            padding: 0;
            background-color: transparent;
            @include font-base();
            font-size: rem(18);
        }

    }

    .credits {
        margin-top: var(--padding-xs);

        @include font-xxs();

        a {
            @include basic-a();
            color: inherit;
            text-decoration: underline;
        }
    }

    a {
        @include basic-a();
        color: inherit;

        &[aria-current="page"] {
            text-decoration: underline;
        }
    }
}