@use "sass:math";

:root {
    --y-header: 0;

    // --padding-xl: #{math.div(120px, 16px) * 1rem};
    // --padding-xl: #{math.div(90px, 16px) * 1rem};
    --padding-xl: #{math.div(60px, 16px) * 1rem};
    --padding-l: #{math.div(50px, 16px) * 1rem};
    --padding-m: #{math.div(40px, 16px) * 1rem};
    --padding-s: #{math.div(30px, 16px) * 1rem};
    --padding-xs: #{math.div(20px, 16px) * 1rem};
    --padding-xxs: #{math.div(15px, 16px) * 1rem};
    --padding-xxxs: #{math.div(10px, 16px) * 1rem};
    --padding-xxxxs: #{math.div(5px, 16px) * 1rem};

    --header-height: #{math.div(120px, 16px) * 1rem};
    --footer-height: #{math.div(99px, 16px) * 1rem};
    
    --scrollbar-height: #{math.div(40px, 16px) * 1rem};
    
    --blur: 3px;
    --radius: #{math.div(10px, 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --footer-height: #{math.div(20px, 16px) * 1rem};
    }  
}
