@use "sass:math";

#Footer {
    --width: 100%;
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-l);
}

#Footer {
    @include font-base();
    @include z-index($z-index-header);

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: var(--padding-v) var(--padding-h);

    position: fixed;
    width: var(--width);
    right: 0;
    @include z-index($z-index-header);
    bottom: 0;
    opacity: 0;

    .credits {
        @include font-xs();

        a {
            @include basic-a();
            color: inherit;
            text-decoration: underline;
        }
    }

    .volume {
        svg {
            width: rem(35);
            height: rem(20);
        }

        button {
            @include font-base();

            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: var(--padding-xxxs);
            padding: 0;
            background-color: transparent;

            svg {
                transform: rotate3d(0, 1, 0, 180deg);
            }

            &.--active svg {
                transform: rotate3d(0, 0, 0, 0);
            }
        }
    }
    
    @media (max-width: $smartphone) {
        display: none;
    }
}
