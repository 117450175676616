#Enhance {
    --color: var(--primary-color);
    --bg: var(--primary-color);
    --padding: var(--padding-s);
}

#Enhance {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(var(--blur));

    opacity: 0;
    pointer-events: none;
    
    @include z-index($z-index-loader);
    color: var(--color);

    &::before {
        background-color: var(--bg);
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: .2;
        @include z-index(0);
    }
    
    > div {
        @include z-index(1);
        position: relative;
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    
    align-items: center;
    display: flex;
    justify-content: center;

    @include font-base();

    .text {
        max-width: rem(275);
        text-align: center;
    }

    button {
        padding: 0;
        background-color: transparent;
        margin-top: var(--padding-s);
        color: var(--color);
        
        svg {
            width: rem(35);
            height: rem(20);
            fill: var(--color);
        }
    }
    
    button:nth-child(2) {
        @include font-base();
        display: flex;
        align-items: center;
        gap: var(--padding-xxs);
    }
    
    button:last-child {
        @include font-xs-uppercase();
    }
}
