@use "sass:math";

:root {
    --font-sans: 'PPRightSerifMono-Regular', sans-serif;
    --font-sans-fine: 'PPRightSerifMono-Fine', sans-serif;

    --font-size-xxxlarge: #{math.div(96px, 16px) * 1rem};
    --font-size-xxlarge: #{math.div(40px, 16px) * 1rem};
    --font-size-xlarge: #{math.div(32px, 16px) * 1rem};
    --font-size-base: #{math.div(20px, 16px) * 1rem};
    --font-size-xsmall: #{math.div(14px, 16px) * 1rem};
    --font-size-xxsmall: #{math.div(12px, 16px) * 1rem};

    --zoom: 1;
    --line-height-sans: 1.27;
    --letter-spacing: 0em;

    --min-font-size: 10px;
    --max-font-size: 48px;
    --font-size: clamp(var(--min-font-size), 1.0582vw * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        --font-size: 16px;

        --font-size-xxxlarge: #{math.div(64px, 16px) * 1rem};
        --font-size-xxlarge: #{math.div(32px, 16px) * 1rem};
        --font-size-xlarge: #{math.div(24px, 16px) * 1rem};
        --font-size-base: #{math.div(16px, 16px) * 1rem};
    }
}

@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans); }
}

@mixin font-sans-fine($line-height:0) {
    font-family: var(--font-sans-fine);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans); }
}

@mixin font-xxxl() {
    @include font-sans-fine();
    --line-height-sans: 1.05;
    font-size: var(--font-size-xxxlarge);
    text-transform: uppercase;
}

@mixin font-xxl() {
    @include font-sans();
    --line-height-sans: 1.05;
    font-size: var(--font-size-xxlarge);
    font-weight: 400;
    text-transform: uppercase;
}

@mixin font-xl() {
    @include font-sans();
    font-size: var(--font-size-xlarge);
    font-weight: 400;
    text-transform: uppercase;
}

@mixin font-base() {
    @include font-sans();
    font-size: var(--font-size-base);
    font-weight: 400;
    text-transform: uppercase;
}

@mixin font-xs() {
    @include font-sans();
    font-size: var(--font-size-xsmall);
    font-weight: 400;
}

@mixin font-xxs() {
    @include font-sans();
    font-size: var(--font-size-xxsmall);
    font-weight: 400;
}

@mixin font-xs-uppercase() {
    @include font-xs();
    text-transform: uppercase;
}