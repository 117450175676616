.widget-gallery {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-l);
    --gap: var(--padding-xxs);    

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-xxxs);
    }
}

.widget-gallery {
    padding: var(--padding-v) var(--padding-h);

    img {
        filter: grayscale(1);
    }

    @include isCursor( ) {
        img:hover {
            filter: grayscale(0);
        }
    }
    
    @media (max-width: $smartphone) {
        .item:not(:last-child) {
            margin-bottom: var(--gap);
        }
    }

    @media (min-width: $smartphone) {
        .wrapper {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: var(--gap);
            position: relative;
            
            > div {
                grid-column: span 1;
                border-radius: var(--radius);
                overflow: hidden;
                
                figure {
                    border-radius: var(--radius);
                    overflow: hidden;
                }
            }

            img {
                filter: grayscale(1);
            }
        }

        @include isCursor( ) {
            img:hover {
                filter: grayscale(0);
            }
        }
    }
}
