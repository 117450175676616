.block-landing {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-l);
    --height: 1;

    @media (max-width: $smartphone) {
        --height: .8;
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
    }
}

.block-landing {
    width: 100vw;
    height: calc(var(--height) * 100vh);
    height: calc(var(--vh, 1vh) * var(--height) * 100);

    display: flex;
    align-items: flex-end;

    @include font-base();

    .wrapper {
        width: 100%;
    }

    .text {
        margin-bottom: var(--padding-m);
    }

    h1 {
        overflow-x: hidden;
        width: calc(50vw - var(--padding-h));

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 0;
            height: 0;
        }

        .items {
            display: flex;
            flex-wrap: nowrap;
            gap: var(--padding-s);
        }

        @include font-xxxl();
    }
    
    @media (max-width: $smartphone) {
        padding: calc(var(--padding-v) + var(--header-height)) var(--padding-h) var(--padding-v);

        h1 {
            width: 100%;
            @include font-xxxl();
        }

        .text {
            margin-bottom: var(--padding-xs);
        }
    } 

    @media (min-width: $smartphone) {
        padding: calc(var(--padding-v) + var(--header-height)) var(--padding-h) calc(var(--padding-v) + var(--footer-height));

        .text {
            margin-bottom: var(--padding-m);
            max-width: rem(470);
        }
    }  
}
