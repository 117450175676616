//↗ → ← ↖

@mixin panel() {
    --bg: var(--primary-color);

    position: relative; 

    > * {
        position: relative;
        @include z-index(2);
    }
    
    &::before {
        content: '';
        @include z-index(0);
        backdrop-filter: blur(var(--blur));
        border-radius: var(--radius);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    
    &::after {
        content: '';
        @include z-index(1);
        background-color: var(--bg);
        border-radius: var(--radius);
        opacity: .15;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.panel {
    @include panel();
}
