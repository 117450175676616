.not-found {
    --color: var(--primary-color);
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-l);
    --gap: var(--padding-s);    

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxxs);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-xs);
    }
}

.not-found {
    @include font-xxl();

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: 100%;
    padding: calc(var(--header-height) + var(--padding-v)) var(--padding-h) calc(var(--header-height) + var(--padding-v));
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    > * {
        max-width: rem(700);
        text-align: center;
    }
}
